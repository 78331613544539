<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 font-weight-medium">
          SSENCIAL WEB
        </h3>
        <p class="text-justify ma-0">
          Configura la información de tu negocio online
        </p>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-container>
            <p class="mb-0 text-subtitle-1">Diseño del sitio</p>
            <p class="mb-0 caption">
              Ajusta parametros de tu tienda
            </p>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-card width="100%" height="200">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-sheet
                        height="20"
                        width="100%"
                        :color="design.primary_color"
                        class="d-flex align-center"
                      >
                        <span
                          class="caption ml-2"
                          :class="{
                            'white--text': design.header_text_color === 'white',
                            'black--text': design.header_text_color === 'black'
                          }"
                          >Business name</span
                        >
                      </v-sheet>
                    </v-col>
                    <v-col cols="3" class="pt-0 pr-0">
                      <v-sheet height="180" width="100%" color="grey lighten-4">
                      </v-sheet>
                    </v-col>
                    <v-col cols="9" class="pt-0 pl-0">
                      <div class="mt-4">
                        <v-row class="mx-2">
                          <v-col cols="3" class="pr-1 py-0" ref="colProduct">
                            <v-sheet
                              :width="shapeProduct"
                              rounded
                              :height="shapeProduct"
                              class="grey lighten-2"
                              :class="{
                                'rounded-circle':
                                  design.product_shape === 'circle'
                              }"
                            ></v-sheet>
                          </v-col>
                          <v-col cols="9" class=" py-0">
                            <v-sheet
                              width="100%"
                              height="10"
                              class="grey lighten-3"
                            ></v-sheet>

                            <div class="d-flex">
                              <span class="text-subtitle-2 mr-2">$$$</span>
                              <v-sheet
                                width="100%"
                                height="10"
                                class="grey lighten-3 mt-1"
                              ></v-sheet>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                <v-card outlined class="mb-2">
                  <p class="ma-0 text-subtitle-2 ml-2 mt-2">Color principal</p>
                  <v-color-picker
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    hide-sliders
                    show-swatches
                    swatches-max-height="80"
                    value="#000000"
                    :disabled="!isAdminUser"
                    @input="primaryColorChanged"
                    class="mb-1"
                  ></v-color-picker>
                  <v-container>
                    <p class="mb-1 caption">
                      Color del texto del encabezado
                    </p>
                    <div class="d-flex justify-center">
                      <v-btn
                        small
                        :disabled="!isAdminUser"
                        class="mr-1 elevation-3"
                        :class="{
                          'elevation-0': design.header_text_color != 'black'
                        }"
                        @click="
                          design.header_text_color = 'black';
                          changesWebSite(true);
                        "
                      >
                        negro</v-btn
                      >
                      <v-btn
                        small
                        :disabled="!isAdminUser"
                        class="ml-1 elevation-3"
                        :class="{
                          'elevation-0': design.header_text_color != 'white'
                        }"
                        dark
                        @click="
                          design.header_text_color = 'white';
                          changesWebSite(true);
                        "
                      >
                        blanco</v-btn
                      >
                    </div>
                  </v-container>
                </v-card>
                <v-card outlined>
                  <v-container>
                    <p class="mb-1 text-subtitle-2">
                      Forma de la vista de productos
                    </p>
                    <div class="d-flex justify-center">
                      <v-btn
                        :disabled="!isAdminUser"
                        text
                        @click="
                          changeProductShape('square');
                          changesWebSite(true);
                        "
                        class="elevation-2"
                        :class="{
                          'elevation-0': design.product_shape != 'square'
                        }"
                      >
                        <v-sheet
                          width="30"
                          rounded
                          height="30"
                          class="grey lighten-2"
                        ></v-sheet>
                        <p class="ma-0 ml-1 caption">Cuadrada</p>
                      </v-btn>

                      <v-btn
                        text
                        :disabled="!isAdminUser"
                        @click="
                          changeProductShape('circle');
                          changesWebSite(true);
                        "
                        class="elevation-2"
                        :class="{
                          'elevation-0': design.product_shape != 'circle'
                        }"
                      >
                        <v-sheet
                          width="30"
                          rounded
                          height="30"
                          class="grey lighten-2 rounded-circle"
                        ></v-sheet>
                        <p class="ma-0 ml-1 caption">redonda</p>
                      </v-btn>
                    </div>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-container>
            <p class="mb-0 text-subtitle-1">Redes sociales</p>
            <p class="mb-2 caption">
              Compartes tus redes sociales directamente desde tu tienda online
            </p>
            <v-text-field
              label="Instagram"
              outlined
              hide-details
              class="mb-2"
              dense
              :disabled="!isAdminUser"
              prepend-inner-icon="alternate_email"
              @input="changesWebSite(true)"
              v-model="socialNetworks.instagram"
            ></v-text-field>
            <v-text-field
              label="Facebook"
              outlined
              hide-details
              class="mb-2"
              dense
              :disabled="!isAdminUser"
              @input="changesWebSite(true)"
              v-model="socialNetworks.twitter"
            ></v-text-field>
            <v-text-field
              label="Twitter"
              outlined
              hide-details
              class="mb-2"
              dense
              :disabled="!isAdminUser"
              @input="changesWebSite(true)"
              v-model="socialNetworks.facebook"
            ></v-text-field>
          </v-container>
        </v-card>
      </v-col>
      <div style="width: 100%;" :style="{ height: heightChanges }"></div>
    </v-row>
    <v-footer padless fixed elevation="6">
      <v-card
        width="100%"
        flat
        tile
        :style="{ marginLeft: $vuetify.breakpoint.lgAndUp ? '250px' : '0' }"
      >
        <v-container>
          <v-btn
            outlined
            block
            small
            class="mb-2"
            :href="getBusiness.marketplace_url"
            target="_blank"
            >Ver sitio web</v-btn
          >
          <v-btn color="primary" small block @click="shareWeb"
            >Compartir web</v-btn
          >
          <v-expand-transition>
            <div class="mt-2" v-if="changes">
              <v-btn small color="success" block @click="updateBusinessData"
                >Guardar cambios</v-btn
              >
            </div>
          </v-expand-transition>
        </v-container>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { firestore } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";
import { loaderDone, loaderStart } from "@/services/LoaderService";

export default {
  data() {
    return {
      shapeProduct: "100%",
      design: {
        primary_color: "#000000",
        header_text_color: "white",
        product_shape: "square"
      },
      socialNetworks: {
        instagram: null,
        twitter: null,
        facebook: null
      },
      heightChanges: "79px",
      changes: false
    };
  },
  computed: {
    ...mapGetters("business", ["getBusiness"]),
    ...mapGetters("user", ["isAdminUser"])
  },
  async mounted() {
    loaderStart();
    const businessData = await this.getBusinessData();
    if (businessData.design) {
      this.design = businessData.design;
      if (businessData.design.product_shape === "circle") {
        this.shapeProduct = this.$refs.colProduct.clientHeight;
      }
    }
    if (businessData.social_networks)
      this.socialNetworks = businessData.social_networks;
    loaderDone();
  },
  methods: {
    ...mapActions("business", ["getBusinessData"]),
    primaryColorChanged(color) {
      this.design.primary_color = color;
      this.changesWebSite(true);
    },
    changeProductShape(typeShape) {
      if (typeShape === "square") {
        this.design.product_shape = "square";
        this.shapeProduct = "100%";
      } else {
        this.design.product_shape = "circle";
        this.shapeProduct = this.$refs.colProduct.clientHeight;
      }
    },
    async shareWeb() {
      const toShare = {
        title: "Marketplace SSENCIAL",
        text: `Tienda oficial de ${this.getBusiness.name}, vistanos dando clic en la siguiente enlace y conoce nuestra variedad de productos.`,
        url: this.getBusiness.marketplace_url
      };
      try {
        await navigator.share(toShare);
      } catch (err) {
        console.log(err);
      }
    },
    async updateBusinessData() {
      loaderStart();
      try {
        const data = {
          design: this.design,
          social_networks: this.socialNetworks
        };
        await firestore("business")
          .doc(this.getBusiness.id)
          .update(data);
        this.changesWebSite(true);
        loaderDone();
      } catch {
        errorMessage({
          message: "Error al actualizar la información del sitio"
        });
        loaderDone();
      }
    },
    changesWebSite(currentValue) {
      if (this.isAdminUser) {
        this.changes = currentValue;
        if (this.changes) this.heightChanges = "99px";
        else this.heightChanges = "79px";
      }
    }
  }
};
</script>

<style>
.v-color-picker__controls {
  display: none;
}
</style>
